import styled from 'styled-components';
import Colors from './Colors';

export const IndexContainer = styled.main`
  display: flex;
  background-color: ${Colors.grey};
  height: 100vh;
`;

export const AppContainer = styled.main<{ windowHeight: number }>`
  background-color: ${Colors.grey};
  display: flex;
  height: ${props => (props.windowHeight ? `${props.windowHeight}px` : '100vh')};
  max-height: ${props => (props.windowHeight ? `${props.windowHeight}px` : '100vh')};
  width: 100vw;

  @media (max-width: 768px) {
    flex-direction: column-reverse;
    background-color: ${Colors.white};
    /* padding-top: 32px;
    box-sizing: border-box; */
  }
`;

export const AppContentContainer = styled.div`
  display: flex;
  height: 100%;

  width: calc(100% - 90px);

  @media (max-width: 768px) {
    width: 100%;
    height: calc(100% - 70px);
  }
`;
export const ModalPageContainer = styled.main`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${Colors.grey};
  height: 100vh;
`;
