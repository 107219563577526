import React from 'react';
import LoginView from '../views/Login/LoginView';
import { ModalPageContainer } from '../styled';
const login = () => {
  return (
    <ModalPageContainer>
      <LoginView />
    </ModalPageContainer>
  );
};

export default login;
